export default {
  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      padding: '0.5rem',
      position: 'static',
      overflowX: 'hidden',
      borderBottom: '2px solid',
      borderColor: 'primary'
    },
    '.containerScrolled': {
      backgroundColor: 'black',
      padding: '0.5rem',
      borderBottom: '2px solid',
      borderColor: 'primary'
    },
    '.menu': {
      display: 'none'
    },
    '.logoLocationContainer': {
      // display: 'none',
      margin: ['', '', '', '0 auto 0 0'],
      position: ['', '', '', 'static'],
      padding: '0rem',
      '.logo': {
        maxWidth: 'unset',
        maxWidth: '225px',

        img: {
          filter: 'unset'
        }
        // marginBottom: '0.5rem',
      },
      '.logoScrolled ': {
        maxWidth: '150px',
        maxheight: 'unset',
        // filter: 'brightness(0) invert(1)',
        display: 'flex',
        img: {
          maxHeight: '80px',
          // maxWidth: '150px',
          height: 'unset'
        },
        a: {
          display: 'flex',
          alignItems: 'center'
        }
      },
      '.locationSwitcher': {
        justifyContent: 'center',
        display: 'none',
        button: {
          fontSize: '0.8rem'
        }
      },
      '.popUp': {
        transform: 'unset'
      }
    },

    '.smallNavMenu': {
      alignItems: 'center',
      '.navItem': {
        color: 'light',
        margin: ['', '', '', '0.75rem', '1rem'],
        a: {
          fontSize: '1rem',
          ':hover': {
            color: 'lightgrey',
            textDecoration: 'underline'
          }
        }
      },
      '.navItem.events': {
        display: 'none'
      },
      '.order-online': {
        backgroundColor: 'primary',
        padding: '.5rem .8rem'
      },
      '.reservations': {
        // margin: '0rem',
        a: {
          padding: '1rem',
          border: 'solid 1px',
          backgroundColor: 'primary',
          borderColor: 'primary'
        }
      }
    },
    '.hamburger': {
      backgroundColor: 'rgb(0 0 0 / 42%)',
      display: ['', '', '', 'none'],
      div: {
        backgroundColor: 'light'
      },
      'div:nth-of-type(2)': {
        width: '80%'
      }
    },
    '.navMenu': {
      transform: 'unset',
      left: '-200vw',
      top: '0rem'
    },
    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['', '', '', '4rem'],
      backgroundColor: 'black',
      width: ['85%', '60%', '50%', '40%'],
      right: 'unset',
      left: '0rem',
      '.navMenuLogo': {
        position: ['', '', '', ''],
        width: ['', '', '', '50%'],
        maxWidth: '200px',
        left: '1rem',
        margin: '0rem 0.5rem 2rem',
        img: {
          maxHeight: ['', '', '', 'unset'],
          maxWidth: ['', '', '', 'unset'],
          width: ['', '', '', '100%']
        }
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto']
      },
      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
          color: 'primary'
        }
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right'],
        color: 'white'
      },
      '.navItem': {
        a: {
          color: 'white'
        }
      },
      '.navItem.events': {
        display: 'none',
        a: {
          color: 'white'
        }
      },

      '.socialIconsContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      },
      '.socialContainer': {
        flexDirection: ['row', 'row', 'row', 'row'],
        svg: {
          path: {
            fill: 'primary'
          }
        }
      }
    },
    '.navBlock': {
      right: 'unset',
      left: 'unset',
      top: '300vh'
    },

    '.navBlockOpen': {
      width: ['15%', '40%', '50%', '60%'],
      right: '0%',
      left: 'unset',
      backgroundSize: 'cover',
      backgroundPosition: 'center center'
    }
  },

  footer: {
    borderTop: '3px solid',
    borderColor: 'primary',
    backgroundColor: 'black',
    padding: ['0rem', '0rem', '0rem', '0rem'],
    maxWidth: 'unset',
    '.socialContainer, .contactDetails-container': {
      svg: {
        path: {
          fill: 'primary'
        }
      }
    },
    '.column': {
      flexDirection: ['', '', '', 'row'],
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      padding: ['2rem', '', '', '2rem 4rem']
    },
    '.bottomBar': {
      padding: ['', '', '0rem 4rem']
    },
    '.logo': {
      filter: 'brightness(0) invert(1)'
    },

    // '.poweredBy .gonationLogo': {
    //   filter: 'brightness(0) invert(0.6)',
    // },
    '.poweredByText': {
      margin: '1rem auto'
    },

    '.copyright': {
      backgroundColor: 'transparent'
    },
    '.multiButtonContainer': {
      justifyContent: ['center', '', '', 'flex-start']
    }
  },

  ctaWidget: {
    // display: 'none',
    a: {
      backgroundColor: 'secondary'
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    width: 'fit-content',
    // borderBottom: '2px solid',
    borderColor: 'secondary',
    display: 'flex',
    flexDirection: 'column',
    fontSize: ['1.75rem', '', '2.5rem'],
    textTransform: 'uppercase',
    color: 'text',
    fontWeight: 'bold',
    letterSpacing: '2px',
    whiteSpace: 'pre-wrap',
    marginBottom: '1.5rem'
  },

  subtitle: {
    color: 'primary',
    fontWeight: 'bold',
    textTransform: 'initial',
    order: '1',
    fontFamily: 'body',
    fontSize: ['1rem', '1.5rem', '1.75rem', '2rem'],
    textTransform: 'uppercase'
  },

  text: {
    order: '3'
  },

  sideBySide1: {
    overflow: 'hidden',
    padding: ['0rem', '1rem', '', '2rem'],
    backgroundColor: 'transparent',
    '.content': {
      order: ['', '', '2'],
      width: ['', '', '45%'],
      padding: ['2rem 1rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      }
    },
    '.lazyload-wrapper': {
      order: ['', '', '1'],
      width: ['', '', '55%'],
      height: ['', '', '80vh'],
      img: {
        // height: '100vh',
        border: ['solid 6px', 'solid 6px', 'solid 10px'],
        borderColor: ['primary', 'primary', 'primary'],

        boxShadow: '2px 2px 10px black'
      }
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.lazyload-wrapper': {
      order: ['', '', '3'],
      width: ['', '', '55%'],
      height: ['', '', '80vh'],
      img: {
        // height: '100vh',
        border: ['solid 6px', 'solid 6px', 'solid 10px'],
        borderColor: ['primary', 'primary', 'primary'],
        boxShadow: '2px 2px 10px black'
      }
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================
  homepageHero: {
    height: ['60vh'],
    justifyContent: 'flex-start',
    alignItems: ['flex-start', '', '', 'center'],
    '.logoHero': {
      display: ['none', '', '', 'block']
    },
    '.slick-slider': {
      width: ['100%', '100%', '100%', '100%'],
      order: ['', '', '', '2'],
      height: ['60vh'],
      '.slick-prev, .slick-next': {
        color: 'white',
        bottom: '0rem',
        backgroundColor: 'primary',
        height: '30px',
        borderRadius: '100px',
        padding: '5px',
        top: 'unset'
      },
      '.slick-prev': {
        left: '1rem'
      },
      '.slick-next': {
        left: '3rem'
      },
      '.slick-dots': {
        left: 'unset',
        right: '1rem',
        flexDirection: 'column',
        width: 'fit-content',
        display: 'flex !important'
      },
      '.slick-dots li button:before': {
        color: 'light'
      },
      '.slick-slide img': {
        height: ['60vh']
      },
      '.slick-slide > div': {
        height: ['60vh']
      }
    },
    '.hero_content_container': {
      width: '100%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      textAlign: 'center',
      margin: '0rem auto',
      alignItems: 'center',
      textShadow: '2px 2px 10px black',
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4',
        alignSelf: 'unset',
        '.ctaButton': {
          variant: 'buttons.primary'
        }
      }
    }
  },
  homepageHeroShout: {
    display: 'none'
  },

  homepageShout: {
    // padding: '0.5rem',
    backgroundColor: 'transparent',

    '.shoutContainer': {
      backgroundColor: 'transparent'
    },
    '.title': {
      border: ['', '', '', '#ffffff solid 9px']
    },
    '.text': {
      backgroundColor: 'transparent',
      fontFamily: 'Handy Casual Condensed',
      fontSize: ['1.6rem', '', '2rem'],
      border: ['', '', '', 'solid 9px'],
      borderColor: ['', '', '', 'primary'],
      padding: ['1rem', '', '', '2rem'],
      order: ['', '', '', '3']
    },
    '.date': {
      backgroundColor: 'background',
      border: ['', '', '', 'solid 9px'],
      borderColor: ['', '', '', 'primary']
    },
    '.imageContainer': {
      backgroundColor: 'background',
      border: ['', '', '', 'solid 9px'],
      borderColor: ['', '', '', 'white']
    }
  },

  homepageFindUs: {
    padding: ['4rem 1rem', '', '', '10rem 1rem'],
    backgroundColor: 'transparent',
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide2'
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide2'
  },

  homepageLocation: {
    variant: 'customVariants.sideBySide1'
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    padding: ['3rem 1rem', '', '6rem 1rem', '7rem 1rem'],
    background: 'transparent',
    '.title': {
      fontSize: '2.5rem',
      color: 'secondary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary'
    }
  },

  // ? ========================
  // ? =====  Menu page  =====
  // ? ========================

  menu: {
    margin: '0rem 0rem',
    maxWidth: 'unset',
    padding: ['2rem 1rem', '', '3rem 2rem'],
    // backgroundImage:
    //   'url(https://res.cloudinary.com/gonation/image/upload/sites/cast-iron-chef-kitchen-bar/pattern-bg.png)',
    // backgroundColor: '#fff8f8',
    backgroundSize: 'auto',
    backgroundRepeat: 'repeat',
    '.backToMenuBtn': {
      variant: 'buttons.secondary',
      margin: '0rem auto 2rem 0rem'
    },
    '.dropdownContainer': {
      display: 'none'
    },
    '.section-cell': {
      width: ['100%', '', 'calc(50% - 1rem)'],
      borderRadius: '0px',
      position: 'relative'
    },
    '.allInContainerWrapper ': {
      maxWidth: '1200px',
      margin: '0rem auto'
    },
    '.cellImageContainer': {
      overflow: 'hidden'
    },
    '.cellImage': {
      borderRadius: '0px',
      transition: 'all ease-in-out 0.5s',
      ':hover': {
        transform: 'scale(1.1) translate(-50%, -50%)',
        transformOrigin: 'center center'
      }
    },
    '.menuCell': {
      borderRadius: '0px',
      '.imageFill': {
        paddingBottom: '60%'
      }
    },

    '.cellName': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'light',
      margin: '0rem',
      fontSize: ['1.5rem', '', '1.75rem', '2.5rem']
    },

    '.tabsRow': {
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      '::before': {
        variant: 'customVariants.title',
        fontSize: ['1.5rem', '1.75rem', '2.5rem', '2.5rem', '3rem'],
        content: "'The Menu'",
        margin: ['0rem 0rem 1.5rem', '', '0rem auto 0rem 0rem'],
        width: ['100%', '', '', 'fit-content'],
        letterSpacing: '0px',
        textTransform: 'capitalize',
        padding: '1rem 0rem'
      },
      '.menuTab': {
        fontSize: '0.9rem',
        border: 'none',
        textTransform: 'capitalize',
        padding: '0.25rem',
        letterSpacing: '-1px',
        fontFamily: 'heading',
        height: 'fit-content',
        fontWeight: '300',
        backgroundColor: 'transparent',
        margin: '1rem 0.25rem 0rem'
      },
      '.menuTabActive': {
        color: 'text',
        borderBottom: '1px solid',
        borderColor: 'primary'
      }
    },

    '.menuContainer': {
      paddingBottom: '0rem'
    },

    '.menuSectionTitle': {
      textAlign: 'left',
      margin: '1rem 0rem 1.5rem',
      fontSize: ['1.25rem', '1.4rem', '1.5rem', '1.6rem'],
      width: 'fit-content',
      color: 'primary',
      borderBottom: 'solid 5px',
      borderColor: 'primary',
      borderRadius: '40px 5px 34px / 7px 334px 63px 198px'
    },
    '.menuSectionDescription': {
      textAlign: 'left',
      // opacity: '0.75',
      fontSize: '0.9rem',
      color: 'light'
    },

    '.menuItemContainer, .menuItemContainerImgActive': {
      margin: '0rem 0rem 1rem',
      padding: ['0rem', '', '0rem 1.5rem 0rem 0rem']
    },

    '.menuItemInnerContainer': {
      margin: '0rem'
    },
    '.menuItemContentContainer': {
      margin: '0rem'
    },

    '.menuItemName': {
      marginBottom: '0rem',
      fontFamily: 'heading',
      color: 'primary',
      fontSize: ['1rem', '1rem', '1.1rem', '1.2rem']
    },
    '.menuItemDescription': {
      color: 'light'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumsContainer': {
      // display: 'none',
    },
    '.albumTitle': {
      variant: 'buttons.secondary'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.no-events-container': {
      img: {
        filter: 'brightness(1) invert(1)'
      }
    },
    '.eventItemImage': {
      minHeight: 'unset',
      maxHeight: 'unset',
      height: '100%'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    padding: '5rem 1rem',
    order: '4',
    border: ['solid 10px black', 'solid 10px black', 'solid 15px black'],
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      color: 'secondary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'primary',
      marginBottom: '2rem',
      variant: 'customVariants.title'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem'
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid',
        borderColor: 'primary',
        backgroundColor: 'rgba(0,0,0,0.5)',
        color: 'white'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid',
        borderColor: 'primary',
        backgroundColor: 'rgba(0,0,0,0.5)',
        color: 'white'
      },
      '.submitBtn': {
        variant: 'buttons.secondary',
        // borderRadius: '0px',
        textAlign: 'center',
        justifyContent: 'center'
      }
    }
  },

  locationMap: {
    order: '2',
    backgroundColor: 'primary',
    color: 'light',
    '.content_container': {
      border: 'solid 10px',
      borderColor: 'secondary'
    },
    h3: {
      textTransform: 'uppercase',
      fontSize: '2rem',
      color: 'white',
      borderBottom: '2px solid',
      borderColor: 'secondary'
    },
    '.dayofWeekText': {
      margin: '0 auto 0 0'
    }
  },

  // ? ========================
  // ? ====  Events Menu  ====
  // ? ========================

  eventsLeft: {
    variant: 'customVariants.sideBySide1'
  },
  eventsRight: {
    variant: 'customVariants.sideBySide2'
  },

  // ? ========================
  // ? ====  Press Menu  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.boxesHeading': {
      display: 'none'
    },
    '.box': {
      backgroundColor: 'secondary',
      margin: ['1rem', '', '2rem'],
      width: ['100%', 'calc(50% - 0.5rem * 2)', 'calc(33.3% - 2rem * 2)']
    },
    '.imageContainer': {
      backgroundColor: 'black',
      img: {
        objectFit: 'contain'
      }
    },
    '.title': {
      color: 'light',
      borderBottom: '2px solid #cababa'
    },
    '.subtitle': {
      color: 'light'
    }
  },

  pressPage: {},

  // ? ========================
  // ? ====  Team Menu  ====
  // ? ========================

  teamMenu: {},

  teamPage: {},

  // ? ========================
  // ? ====  blog Menu  ====
  // ? ========================

  blogMenu: {},

  blogPage: {}
}
